/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDTpGSLXpYYbQhvm_rFaDF-lkgB8_4scVQ",
  "appId": "1:1043909548710:web:a930aaa264b79715d6f267",
  "authDomain": "schooldog-i-tuscaloosa-al.firebaseapp.com",
  "measurementId": "G-63HM2QTN7H",
  "messagingSenderId": "1043909548710",
  "project": "schooldog-i-tuscaloosa-al",
  "projectId": "schooldog-i-tuscaloosa-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-tuscaloosa-al.appspot.com"
}
